import React, { useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { NotificationManager } from "react-notifications";

import { AuthApi } from "states/api";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useStores } from "_common/hooks";

import { Link, useNavigate } from "react-router-dom";

import i18next from "i18next";
import Loading from "_common/component/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faUser } from "@fortawesome/free-solid-svg-icons";

const schema = yup
  .object({
    username: yup.string().required(i18next.t("verify_require")),
    password: yup.string().required(i18next.t("verify_require")),
  })
  .required();

export default function LoginForm({ setTypeOpen }) {
  const { authStore } = useStores();
  const navigate = useNavigate();
  const { mutate, isLoading } = useMutation(
    (variables) => AuthApi.login(variables),
    {
      onSuccess: (res) => {
        authStore.updateUser(res?.data);
        setTimeout(() => {
          navigate("/");
        }, 200);
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleLogin = (values) => {
    if (isLoading) return;
    mutate(values);
  };

  return (
    <form className="auth-sign-in" onSubmit={handleSubmit(handleLogin)}>
      {isLoading && <Loading />}
      <div className="sbui-space-col sbui-space-y-6">
        <div className="sbui-space-col sbui-space-y-3">
          <div>
            <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
              <div className="sbui-formlayout__content-container-horizontal">
                <div className="sbui-input-container">
                  <input
                    type="text"
                    placeholder={i18next.t("username_login")}
                    className="sbui-input sbui-input--with-icon sbui-input--xlarge"
                    {...register("username")}
                  />
                  <div className="sbui-input-icon-container">
                    <FontAwesomeIcon icon={faUser} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
              <div className="sbui-formlayout__content-container-horizontal">
                <div className="sbui-input-container">
                  <input
                    type="text"
                    placeholder={i18next.t("verify_require_password")}
                    className="sbui-input sbui-input--with-icon sbui-input--xlarge"
                    {...register("password")}
                  />
                  <div className="sbui-input-icon-container">
                    <FontAwesomeIcon icon={faLock} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sbui-space-col sbui-space-y-6">
          <span className="sbui-btn-container sbui-btn--w-full">
            <button
              className="sbui-btn sbui-btn-primary btn-register sbui-btn--w-full sbui-btn-container--shadow sbui-btn--xlarge sbui-btn--text-align-center"
              type="submit"
              disabled={!isValid || isLoading}
            >
              <span> {i18next.t("login")}</span>
            </button>
          </span>
        </div>
        <div
          className="sbui-space-col sbui-space-y-2"
          style={{ textAlign: "center" }}
        >
          <span
            onClick={() => navigate("/auth/register")}
            className="inline-block px-4 py-2 mx-auto text-default"
          >
            {i18next.t("register")}
          </span>
        </div>
      </div>
    </form>
  );
}

import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faVolumeHigh } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";

import { PriceApi } from "states/api/price";
import { useQuery } from "@tanstack/react-query";

import icon1 from "assets/img/icon/notice.png";
import icon2 from "assets/img/icon/cskh.png";
import icon5 from "assets/img/icon/icon5.png";
import icon6 from "assets/img/icon/legal_card.png";
import icon7 from "assets/img/icon/icon7.png";
import icon8 from "assets/img/icon/icon8.png";
import icon9 from "assets/img/icon/translate.png";

import banner1 from "assets/img/banner/banner1.jpg";
import banner2 from "assets/img/banner/banner2.jpg";
import { formatMoney, formatNumber } from "_common/utils/formatValue";
import HeaderMobile from "_common/component/Layout/Header/HeaderMobile";
import classNames from "classnames";
import i18next from "i18next";

const HomeUnAuth = observer(() => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const {
    authStore: { banners, top_coin, games, authenticated },
  } = useStores();

  const [active, setActive] = useState("code1");

  const { data, refetch } = useQuery(
    ["prices", "list"],
    () =>
      PriceApi.getList({
        params: {
          page: 1,
          limit: 10,
          ...params,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const coinTopPrices = useMemo(
    () =>
      top_coin.map((item) => {
        const price = data?.find((obj) => obj?.symbol === item?.code);

        return { ...item, price };
      }),
    [top_coin, data]
  );

  const coinListPrice = useMemo(
    () =>
      games.map((item) => {
        const price = data?.find(
          (obj) => obj?.symbol?.toLowerCase() === item?.code
        );

        return { ...item, price };
      }),
    [games, data]
  );

  return (
    <>
      <HeaderMobile />
      <div className="home-page">
        <Swiper
          spaceBetween={30}
          centeredSlides
          pagination
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          modules={[Pagination, Autoplay]}
          className="swiper2 "
        >
          {banners.map((item, index) => (
            <SwiperSlide key={`slide-${index?.toString()}`}>
              <img alt="" src={item} />
            </SwiperSlide>
          ))}
        </Swiper>

        <div className="notice">
          <div className="left">
            <FontAwesomeIcon icon={faVolumeHigh} />
            <span>{i18next.t("10_langs_msg")}</span>
          </div>
        </div>
        <div className="top-coin">
          {coinTopPrices.map((item) => (
            <Link key={item?.code} to="/" className="item">
              <h5>
                <b>{item?.name?.split("/")?.[0]}</b>/
                <span>{item?.name?.split("/")?.[1]}</span>
              </h5>
              <h4
                className={classNames("price", {
                  "text-green": item?.price?.priceChangePercent > 0,
                  "text-red": item?.price?.priceChangePercent <= 0,
                })}
              >
                {formatNumber(item?.price?.lastPrice, "0,0.[00000]")}
              </h4>
              <p
                className={classNames({
                  "text-green": item?.price?.priceChangePercent > 0,
                  "text-red": item?.price?.priceChangePercent <= 0,
                })}
              >
                {formatNumber(item?.price?.priceChangePercent, "0,0.[00]")}%
              </p>
            </Link>
          ))}
        </div>

        {authenticated ? (
          <>
            <div className="quick-action">
              <Link to="/cskh" className="item">
                <img src={icon2} alt="" />
                <p>{i18next.t("online_customer_care")}</p>
              </Link>
              <Link to="/board/member/language" className="item">
                <img src={icon9} alt="" />
                <p>{i18next.t("switch_language")}</p>
              </Link>
            </div>
            <div className="quick-buy">
              <div className="wrapper">
                <div className="col1">
                  <Link className="item" to="/board/recharge">
                    <div>
                      <h5>{i18next.t("fast_recharge")}</h5>
                      <p className="text-gray-400 text-sm">
                        <small>{i18next.t("near_by")}</small>
                      </p>
                      <p className="text-gray-400 text-md">
                        <small>BTC, USDT, ETH, v.v.</small>
                      </p>
                    </div>
                    <div>
                      <img src={icon6} alt="" />
                    </div>
                  </Link>
                </div>
                <div className="col2">
                  <Link className="item" to="/board/trade">
                    <img src={icon1} alt="" />
                    <p>{i18next.t("futures_trading")}</p>
                  </Link>
                  <Link className="item" to="/board/member/introduction">
                    <img src={icon5} alt="" />
                    <p>{i18next.t("support_center")}</p>
                  </Link>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="quick-buy">
            <div className="wrapper">
              <Link className="item" to="/auth/login">
                <div>
                  <h5>{i18next.t("login_start_trading")}?</h5>
                </div>
                <FontAwesomeIcon icon={faArrowRight} />
              </Link>
            </div>
          </div>
        )}

        <div className="banner-notice">
          <div
            className="item"
            style={{ backgroundImage: `url(${banner2})` }}
            onClick={() => navigate("/board/fund")}
          />
        </div>
        <div className="coin-list">
          <div className="list">
            <div className="item tab">
              <div
                className={classNames("col1", {
                  active: active === "code1",
                })}
                style={{ alignItems: "center" }}
                onClick={() => setActive("code1")}
              >
                {i18next.t("increase_list")}
              </div>

              <div
                className={classNames("col2", {
                  active: active === "code2",
                })}
                style={{ alignItems: "center" }}
                onClick={() => setActive("code2")}
              >
                {i18next.t("volume")}
              </div>
            </div>
            <div className="item header">
              <div className="col1">{i18next.t("first_name")}</div>
              <div className="col2">{i18next.t("latest_price")}</div>
              <div className="col3">
                {i18next.t("increase_decrease_amplitude")}
              </div>
            </div>

            {coinListPrice?.map((item) => (
              <Link
                className="item"
                to={`/board/trade?symbol=${item?.code}`}
                key={item?.id}
              >
                <div className="col1">
                  <p className="name">
                    <b>{item?.name?.split("/")?.[0]}</b>
                    <span>/{item?.name?.split("/")?.[1]}</span>
                  </p>
                </div>
                <div className="col2">
                  {formatNumber(item?.price?.lastPrice, "0,0.[00000]")}
                </div>
                <div className="col3">
                  {active === "code1" && (
                    <span
                      className={classNames("tag ", {
                        green: item?.price?.priceChangePercent > 0,
                        red: item?.price?.priceChangePercent <= 0,
                      })}
                    >
                      {formatNumber(
                        item?.price?.priceChangePercent,
                        "0,0.[00]"
                      )}
                      %
                    </span>
                  )}
                  {active === "code2" && (
                    <span className={classNames("tag blue ", {})}>
                      {formatMoney(item?.price?.volume)}
                    </span>
                  )}
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </>
  );
});

export default HomeUnAuth;

import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import i18next from "i18next";
import { formatNumber } from "_common/utils/formatValue";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import { useQuery } from "@tanstack/react-query";
import { PriceApi } from "states/api/price";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";

import Usdt from "./Usdt";

const MarketPage = observer(() => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const {
    authStore: { banners, top_coin, games },
  } = useStores();

  const [gameList, setGameList] = useState(games);
  const [active, setActive] = useState("usdt");

  const { data, refetch } = useQuery(
    ["prices", "list"],
    () =>
      PriceApi.getList({
        params: {
          page: 1,
          limit: 10,
          ...params,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const coinListPrice = useMemo(
    () =>
      gameList.map((item) => {
        const price = data?.find(
          (obj) => obj?.symbol?.toLowerCase() === item?.code
        );

        return { ...item, price };
      }),
    [gameList, data]
  );

  const handleKeydown = (event) => {
    if (event?.keyCode === 13) {
      const valueKey = event?.target?.value;
      const cloneData = [...gameList];
      const result = cloneData.filter(
        (obj) => obj?.name?.toLowerCase()?.search(valueKey?.toLowerCase()) > -1
      );
      if (!valueKey) {
        setGameList(games);
      } else {
        setGameList(result);
      }
    }
  };

  return (
    <div className="home-page market-page">
      <HeaderTop title={i18next.t("market")} isBack={false} />
      <Swiper
        spaceBetween={30}
        centeredSlides
        pagination
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        modules={[Pagination, Autoplay]}
        className="swiper2 "
      >
        {banners.map((item, index) => (
          <SwiperSlide key={`slide-${index?.toString()}`}>
            <img alt="" src={item} />
          </SwiperSlide>
        ))}
      </Swiper>
      <Usdt />
    </div>
  );
});

export default MarketPage;

import { useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useStores } from "_common/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";

function HeaderTop({ title, position = "", isBack = true }) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user, game_wallet },
  } = useStores();

  const onGetProfile = () => {
    queryClient.invalidateQueries(["get_profile"]);
  };

  return (
    <div className="bg_header ">
      {isBack && (
        <span className="btn_back" onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faAngleLeft} />
        </span>
      )}
      <h1
        className={classNames("bg_header_name", {
          [position]: true,
        })}
      >
        {title}
      </h1>
    </div>
  );
}

export default HeaderTop;

import React, { useEffect, useMemo, useState } from "react";
import { get } from "lodash";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { formatBalance, formatNumber } from "_common/utils/formatValue";
import classNames from "classnames";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import { useStores } from "_common/hooks";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { GameApi } from "states/api/game";
import { NotificationManager } from "react-notifications";
import { useNotify } from "_common/component/NotifyProvider";
import i18next from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins, faDollarSign } from "@fortawesome/free-solid-svg-icons";
import ModalBet from "./ModalBet";

const GameBoard = observer(({ game_id, data_game }) => {
  const queryClient = useQueryClient();
  const { isMobile } = useMediaQuery();
  const { pushNotify, betGame } = useNotify();
  const {
    authStore: { user, setCoinOption, loading, setLoading, general },
  } = useStores();

  const [game_type, setGameType] = useState();
  const [openModal, setOpenModal] = useState(false);

  const handleSubmit = (betGame) => {
    setGameType(betGame);
    setOpenModal(true);
  };

  const handleClose = () => {
    setGameType();
    setOpenModal(false);
  };

  return (
    <div className="trade-footer">
      <Link
        type="button"
        to="/board/trade/history"
        className="btn btn-history "
      >
        <FontAwesomeIcon icon={faCoins} />
        <span>{i18next.t("trade_history")}</span>
      </Link>
      <button
        type="button"
        disabled={loading}
        onClick={() => handleSubmit("buy")}
        className="btn btn-buy "
      >
        <FontAwesomeIcon icon={faDollarSign} />
        <span>{i18next.t("buy")}</span>
      </button>
      <button
        type="button"
        disabled={loading}
        onClick={() => handleSubmit("sell")}
        className="btn btn-sell"
      >
        <FontAwesomeIcon icon={faDollarSign} />
        <span>{i18next.t("sell")}</span>
      </button>
      {openModal && (
        <ModalBet
          type={game_type}
          onClose={handleClose}
          game_id={game_id}
          data_game={data_game}
          open={openModal}
        />
      )}
    </div>
  );
});

export default GameBoard;

import i18next from "i18next";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useStores } from "_common/hooks";

// redux

import LoginForm from "./LoginForm";

function LoginPage() {
  const navigate = useNavigate();
  const {
    authStore: { banner_login_top, banner_login_footer, logo },
  } = useStores();

  return (
    <div className="sbui-sidepanel-container ">
      <div>
        <div className="sbui-sidepanel--medium auth-page">
          <div className="sbui-sidepanel  py-10 page-auth">
            <div
              className="sbui-space-col sbui-space-y-6"
              style={{ height: "100%", zIndex: 99 }}
            >
              <div className="sbui-sidepanel-content auth-container">
                <div className="flex flex-col  h-full">
                  <div className="sbui-auth">
                    <div className="sbui-space-col sbui-space-y-8 sbui-auth-label">
                      <img id="logo" src={logo} alt="" />
                      <LoginForm />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;

import classNames from "classnames";
import React, { useEffect, useState } from "react";

import { Link, useLocation } from "react-router-dom";
import { useStores } from "_common/hooks";
import icon1 from "assets/img/icon/index1.png";
import icon2 from "assets/img/icon/index2.png";
import icon3 from "assets/img/icon/index3.png";
import icon4 from "assets/img/icon/index4.png";
import user from "assets/img/icon/user.png";
import i18next from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faFileLines,
  faStar,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

export default function MobileFooter() {
  const location = useLocation();
  const pathName = location?.pathname;
  const {
    authStore: { general },
  } = useStores();

  return (
    <div className="footer">
      {/* <Link
        to="/"
        className={classNames("footer_item", {
          active: pathName === "/",
        })}
      >
        <img src={icon1} alt="" />
        <h5>{i18next.t("page_home")}</h5>
      </Link> */}
      <Link
        to="/"
        className={classNames("footer_item", {
          active: pathName === "/",
        })}
      >
        <FontAwesomeIcon icon={faChartLine} />
        <h5>{i18next.t("market")}</h5>
      </Link>
      <Link
        to="/board/trade/history"
        className={classNames("footer_item", {
          active: pathName === "/board/trade/history",
        })}
      >
        <FontAwesomeIcon icon={faFileLines} />
        <h5>{i18next.t("transaction")}</h5>
      </Link>
      <Link
        to="/board/member/introduction"
        className={classNames("footer_item", {
          active: pathName === "/board/member/introduction",
        })}
      >
        <FontAwesomeIcon icon={faStar} />
        <h5>{i18next.t("event")}</h5>
      </Link>
      <Link
        to="/board/member"
        className={classNames("footer_item", {
          active: pathName === "/board/member",
        })}
      >
        <FontAwesomeIcon icon={faUser} />
        <h5>{i18next.t("mine")}</h5>
      </Link>
    </div>
  );
}

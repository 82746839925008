import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faAngleRight,
  faFileInvoice,
  faFileLines,
  faPen,
  faIdCard,
  faGlobe,
  faHeadset,
  faPowerOff,
} from "@fortawesome/free-solid-svg-icons";
import { NotificationManager } from "react-notifications";

// icon
import { Link, useNavigate } from "react-router-dom";
import { useStores } from "_common/hooks";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AuthApi } from "states/api";
import { observer } from "mobx-react-lite";
import i18next from "i18next";

import icon11 from "assets/img/icon/icon11.png";
import icon12 from "assets/img/icon/icon12.png";
import icon13 from "assets/img/icon/icon13.png";
import icon14 from "assets/img/icon/icon14.png";
import icon15 from "assets/img/icon/icon15.png";
import icon16 from "assets/img/icon/icon16.png";
import icon17 from "assets/img/icon/icon17.png";
import icon18 from "assets/img/icon/icon18.png";
import icon19 from "assets/img/icon/icon19.png";
import btc from "assets/img/icon/btc.png";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import { formatNumber } from "_common/utils/formatValue";

const MemberPage = observer(() => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user, link_download, logo, clear },
  } = useStores();

  const { mutate: onLogout } = useMutation(AuthApi.logout, {
    onSuccess: (res) => {
      clear();
    },
    onError: (error) => {
      clear();
      const errorMessage =
        i18next.t(error?.message) ?? i18next.t("action_failed_msg");
      NotificationManager.error(errorMessage);
    },
  });

  const onGetProfile = () => {
    queryClient.invalidateQueries(["get_profile"]);
  };

  return (
    <div className="member-page">
      <HeaderTop title={i18next.t("member")} isBack={false} />
      <div className="member-header">
        <img src={user?.avatar_url} className="avatar" alt="" />
        <div className="info">
          <p className="vip">{user?.level_text}</p>
          <p className="username">{user?.full_name || user?.username}</p>
          <p className="balance">
            {i18next.t("balance")}: {formatNumber(user?.balance, "0.00")} USD
          </p>
        </div>
      </div>
      <div className="container_main">
        <ul className="memberMenu">
          <li>
            <Link to="/board/history">
              <div>
                <FontAwesomeIcon icon={faFileLines} />
                <span>{i18next.t("transaction_history")}</span>
              </div>
              <FontAwesomeIcon icon={faAngleRight} className="angle-right" />
            </Link>
          </li>
          <li>
            <Link to="/board/member/security">
              <div>
                <FontAwesomeIcon icon={faPen} />
                <span>{i18next.t("change_password")}</span>
              </div>
              <FontAwesomeIcon icon={faAngleRight} className="angle-right" />
            </Link>
          </li>
          <li>
            <div
              className="link"
              onClick={() => {
                if (user?.is_verify === 2) {
                  navigate("/board/member/kyc");
                }
              }}
            >
              <div>
                <FontAwesomeIcon icon={faIdCard} />
                <span>{i18next.t("premium_verification")}</span>
              </div>
              <div>
                <FontAwesomeIcon icon={faAngleRight} className="angle-right" />
              </div>
            </div>
          </li>
          <li>
            <Link to="/board/member/language">
              <div>
                <FontAwesomeIcon icon={faGlobe} />
                <span>{i18next.t("language")}</span>
              </div>
              <FontAwesomeIcon icon={faAngleRight} className="angle-right" />
            </Link>
          </li>
          <li>
            <Link to="/cskh">
              <div>
                <FontAwesomeIcon icon={faHeadset} />
                <span>{i18next.t("online_customer_care")}</span>
              </div>
              <FontAwesomeIcon icon={faAngleRight} className="angle-right" />
            </Link>
          </li>
          <li>
            <div className="link" onClick={() => onLogout()}>
              <div>
                <FontAwesomeIcon icon={faPowerOff} />
                <span>{i18next.t("logout")}</span>
              </div>
              <FontAwesomeIcon icon={faAngleRight} className="angle-right" />
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
});

export default MemberPage;

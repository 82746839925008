import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Portal } from "react-portal";
import { gameBetItemType } from "_common/constants/statusType";
import { useStores } from "_common/hooks";
import { formatNumber } from "_common/utils/formatValue";
import i18next from "i18next";

function ModalBonus({ open, data, onClose }) {
  const {
    authStore: { general },
  } = useStores();

  return (
    <Portal>
      <div
        className="modal modal-bet-game"
        style={{ display: open ? "block" : "none" }}
      >
        <div className="mask" />
        <div className="modal-content">
          <div className="modal-header">
            <div className="btn-close" onClick={() => onClose()}>
              X
            </div>
          </div>
          <div className="classic-bet-now">
            <div className="title">
              {data?.status === "win" && (
                <h2 className="text-green">
                  <b>+{formatNumber(data?.amount_payback, "0,0.00")} USDT</b>
                </h2>
              )}
              {data?.status === "lose" && (
                <h2 className="text-red">
                  <b>-{formatNumber(data?.amount_lose, "0,0.00")} USDT</b>
                </h2>
              )}
              <p className="text-gray-500">{i18next.t("completed_due_date")}</p>
            </div>
            <div className="content">
              <div className="table-info">
                <div className="item">
                  <span>{i18next.t("direction")}</span>
                  <span
                    className={classNames({
                      "text-green": data?.bet_game === "buy",
                      "text-red": data?.bet_game === "sell",
                    })}
                  >
                    {i18next.t(gameBetItemType[data?.bet_game])}
                  </span>
                </div>
                <div className="item">
                  <span>{i18next.t("amount_2")}</span>
                  <span>{formatNumber(data?.amount)} USDT</span>
                </div>
                <div className="item">
                  <span>{i18next.t("exercise_price")}</span>
                  <span>{formatNumber(data?.open, "0,0.[00000]")}</span>
                </div>
              </div>
            </div>
            <div className="submit">
              <button
                type="button"
                className="btn btn-submit"
                style={{ width: 200 }}
                onClick={onClose}
              >
                {i18next.t("continue_placing_orders")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
}

export default ModalBonus;

import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import i18next from "i18next";
import { get } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { GamePlayerApi } from "states/api/gamePlayer";
import NoData from "_common/component/NoData";
import { formatNumber } from "_common/utils/formatValue";
import ModalHistoryDetail from "./ModalHistoryDetail";

function ListData({ data }) {
  const [open, setOpen] = useState(false);
  const [detail, setDetail] = useState(false);

  if (!data?.length) return <NoData />;
  return (
    <>
      <div className="list-history-data">
        {data?.map((item) => (
          <div
            className="item"
            key={item?.id}
            onClick={() => {
              setOpen(true);
              setDetail(item);
            }}
          >
            <div className="flex  justify-between items-center gap-2 mt-2">
              <div className="flex flex-col  w-full flex2">
                <h4 className="text-xl font-bold">
                  <span style={{ display: "inline-block", width: 116 }}>
                    {item?.game?.name}
                  </span>
                  <span className="text-sm text-red">
                    {i18next.t(item?.bet_game)} {formatNumber(item?.amount)}
                  </span>
                </h4>

                <p className="text-sm">
                  {formatNumber(item?.open, "0,0.[0000]")} -{" "}
                  {formatNumber(item?.close, "0,0.[0000]")}
                </p>
                <p className="text-sm ">
                  {moment(item?.created_at).format("DD/MM/YYYY HH:mm:ss")}
                </p>
              </div>
              <div className="flex flex-col items-end w-full flex1">
                {item?.status === "win" && (
                  <h4 className="text-xl font-bold text-green">
                    {formatNumber(item?.amount_payback, "0,0.00")}
                  </h4>
                )}
                {item?.status === "lose" && (
                  <h4 className="text-xl font-bold text-red">
                    {formatNumber(item?.amount_lose, "0,0.00")}
                  </h4>
                )}
                <p className="text-sm">
                  {item?.created_at &&
                    moment(item?.created_at)
                      .add("seconds", item?.type)
                      .format("DD/MM/YYYY HH:mm:ss")}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
      {open && (
        <ModalHistoryDetail
          open={open}
          onClose={() => {
            setOpen(false);
            setDetail(false);
          }}
          data={detail}
        />
      )}
    </>
  );
}

export default function HistoryData() {
  const [active, setActive] = useState("bet");

  const { data, refetch } = useQuery(
    ["game-player", "list", active],
    () =>
      GamePlayerApi.getList({
        params: {
          page: 1,
          limit: 10,
          type: "trade",
          status: active,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, [active]);

  return (
    <>
      <div className="tabs w-full">
        <div
          className={classNames("tab  w-full", {
            active: active === "bet",
          })}
          onClick={() => setActive("bet")}
        >
          {i18next.t("holding")}
        </div>
        <div
          className={classNames("tab  w-full", {
            active: active === "complete",
          })}
          onClick={() => setActive("complete")}
        >
          {i18next.t("history")}
        </div>
      </div>
      <div className="w-full">
        <ListData data={data?.data || []} />
      </div>
    </>
  );
}

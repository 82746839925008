import React, { useEffect, useMemo, useState } from "react";
import { get } from "lodash";
import { observer } from "mobx-react-lite";
import {
  formatBalance,
  formatMoney,
  formatNumber,
} from "_common/utils/formatValue";
import classNames from "classnames";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import { useStores } from "_common/hooks";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { GameApi } from "states/api/game";
import { NotificationManager } from "react-notifications";
import { useNotify } from "_common/component/NotifyProvider";
import i18next from "i18next";
import { Portal } from "react-portal";
import moment from "moment";

const ModalHistoryDetail = observer(({ open, onClose, data }) => {
  const queryClient = useQueryClient();
  const { isMobile } = useMediaQuery();
  const { pushNotify, dataGame, betGame } = useNotify();
  const {
    authStore: { user, loading, setLoading, general },
  } = useStores();

  if (!open) return <div />;

  return (
    <Portal>
      <div className="modal modal-bet modal-full ">
        <div className="mask" />
        <div className="modal-content">
          <div className="modal-header">
            <span className="close" onClick={onClose}>
              x
            </span>
          </div>
          <div className="modal-body">
            <div className="info-table-flex">
              <div className="item">
                <span className="label">{i18next.t("transaction_no")}</span>
                <span className="content">{data?.game?.name}</span>
              </div>
              <div className="item">
                <span className="label">{i18next.t("purchase_price")}</span>
                <span className="content">
                  {formatNumber(data?.open, "0,0.[0000]")}
                </span>
              </div>
              <div className="item">
                <span className="label">{i18next.t("saleprice")}</span>
                <span className="content">
                  {formatNumber(data?.close, "0,0.[0000]")}
                </span>
              </div>
              <div className="item">
                <span className="label">{i18next.t("fee")}</span>
                <span className="content">
                  {formatNumber(data?.fee, "0.[000]")}
                </span>
              </div>
              <div className="item">
                <span className="label">{i18next.t("profit_amount")}</span>
                {data?.status === "lose" && (
                  <span className="content">
                    {formatNumber(data?.amount_lose, "0,0.[000]")}
                  </span>
                )}
                {data?.status === "win" && (
                  <span className="content">
                    {formatNumber(data?.amount_payback, "0,0.[000]")}
                  </span>
                )}
              </div>
              <div className="item">
                <span className="label">{i18next.t("transaction_time")}</span>
                <span className="content">
                  {data?.created_at &&
                    moment(data?.created_at).format("YYYY-MM-DD HH:mm:ss")}
                </span>
              </div>
              <div className="item">
                <span className="label">{i18next.t("close_time")}</span>
                <span className="content">
                  {data?.created_at &&
                    moment(data?.created_at)
                      .add("seconds", data?.type)
                      .format("YYYY-MM-DD HH:mm:ss")}
                </span>
              </div>
            </div>
            <span className="signin-foote" onClick={() => onClose()}>
              —— Return! ——
            </span>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn-cancel"
              onClick={() => onClose()}
            >
              {i18next.t("cancel")}
            </button>
          </div>
        </div>
      </div>
    </Portal>
  );
});

export default ModalHistoryDetail;

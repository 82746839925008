import React, { useEffect, useState } from "react";
import { useStores } from "_common/hooks";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import i18next from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBill, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";

import HeaderTop from "_common/component/Layout/Header/HeaderTop";

export default function Introduction() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user, questions, image_introduction },
  } = useStores();

  return (
    <div className="container introduce-page  mt-10">
      <HeaderTop title={i18next.t("support_center")} />
      <Swiper
        spaceBetween={30}
        centeredSlides
        pagination
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        modules={[Pagination, Autoplay]}
        className="slide-question"
      >
        {questions.map((item, index) => (
          <SwiperSlide key={`slide-${index?.toString()}`}>
            <div className="item">
              <img src={item?.image_url} alt="" />
              <div className="info">
                <p>{item?.date}</p>
                <p>{i18next.t(item?.title)}</p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="list-questions">
        {questions?.map((item, index) => (
          <div
            className="item"
            key={`item-${index.toString()}`}
            onClick={() =>
              navigate(`/board/member/introduction/detail/${item?.id}`)
            }
          >
            <span>{i18next.t(item?.title)}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

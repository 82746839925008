import React from "react";
import icon_noMessage from "assets/img/empty-data.png";
import i18next from "i18next";

export default function NoData({ description = "no_data" }) {
  return (
    <div className="noMSG_out nodata">
      <div className="noMSG">
        <img alt="kubet" src={icon_noMessage} />
        <div>{i18next.t(description)}</div>
      </div>
    </div>
  );
}

import React, { useEffect, useMemo, useState } from "react";
import { get } from "lodash";
import { observer } from "mobx-react-lite";
import {
  formatBalance,
  formatMoney,
  formatNumber,
} from "_common/utils/formatValue";
import classNames from "classnames";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import { useStores } from "_common/hooks";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { GameApi } from "states/api/game";
import { NotificationManager } from "react-notifications";
import { useNotify } from "_common/component/NotifyProvider";
import i18next from "i18next";
import { Portal } from "react-portal";

import { PriceApi } from "states/api/price";
import HistoryData from "./HistoryData";
import MoneyInput from "./MoneyInput";
import MoneySelect from "./MoneySelect";

const gameTypeList = [
  {
    time: 120,
    profit: 5,
    min: 100,
    max: 5000,
  },
  {
    time: 180,
    profit: 10,
    min: 10000,
    max: 30000,
  },
  {
    time: 300,
    profit: 15,
    min: 50000,
    max: 100000,
  },
  {
    time: 360,
    profit: 20,
    min: 150000,
    max: 250000,
  },
  {
    time: 420,
    profit: 25,
    min: 300000,
    max: 500000,
  },
  {
    time: 480,
    profit: 30,
    min: 500000,
    max: 1000000,
  },
];

const ModalBet = observer(({ open, onClose, type, data_game, game_id }) => {
  const queryClient = useQueryClient();
  const { isMobile } = useMediaQuery();
  const { pushNotify, dataGame, betGame } = useNotify();
  const {
    authStore: { user, loading, setLoading, general },
  } = useStores();

  const [game_type, setGameType] = useState(gameTypeList[0]?.time);
  const [amount, setAmount] = useState(Number(get(general, "bet_default", 0)));

  const { mutate: onSubmit } = useMutation(
    (variables) =>
      GameApi.betGame({
        params: {
          game_id,
          ...variables,
        },
      }),
    {
      onSuccess: (res, variables) => {
        setAmount(Number(get(general, "bet_default", 0)));
        queryClient.invalidateQueries(["get_profile"]);
        queryClient.invalidateQueries(["game-player", "list"]);
        NotificationManager.success(
          i18next.t(res?.msg) || i18next.t("action_success_msg")
        );

        betGame({
          user_id: user?.id,
          time: variables?.type,
          game_id: res?.data?.id,
          ...variables,
        });
        pushNotify({
          type: "betGame",
          status: "new_bet",
        });
        onClose();
      },
      onError: (error) => {
        setLoading(false);
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const handleSubmit = async () => {
    setLoading(true);
    if (loading) return null;
    if (!amount)
      return NotificationManager.error(i18next.t("enter_large_amount_msg"));
    if (!game_type)
      return NotificationManager.error(i18next.t("select_game_type_msg"));
    if (!game_id)
      return NotificationManager.error(i18next.t("select_game_type_msg"));
    const itemType = gameTypeList?.find((obj) => obj?.time === game_type);

    let profit = 0;
    if (itemType) {
      if (itemType?.min > amount) {
        setLoading(false);
        return NotificationManager.error(
          `${i18next.t("enter_large_amount_msg")} ${formatMoney(itemType?.min)}`
        );
      }
      if (itemType?.max < amount) {
        setLoading(false);
        return NotificationManager.error(
          `${i18next.t("enter_smaller_amount_msg")} ${formatMoney(
            itemType?.max
          )}`
        );
      }
      const numRate = Number(itemType?.profit) / 100;
      profit = amount * numRate;
    }

    if (profit <= 0) {
      setLoading(false);
      return NotificationManager.error(i18next.t("enter_large_amount_msg"));
    }

    onSubmit({
      amount,
      profit,
      type: game_type,
      bet_game: type,
      price: dataGame?.lastPrice,
    });
    return null;
  };

  const usdtBalance = useMemo(
    () => user?.tokens?.find((obj) => obj.symbol === "usdt"),
    [user]
  );

  if (!open) return <div />;

  return (
    <Portal>
      <div className="modal modal-bet">
        <div className="mask" />
        <div className="modal-content">
          <div className="modal-header">
            <h5>{i18next.t("confirm_order")}</h5>
            <span className="btn-close" onClick={onClose}>
              x
            </span>
          </div>
          <div className="modal-body">
            <p className="!text-white">{i18next.t("time")}</p>
            <div className="list-bet">
              {gameTypeList.map((item) => (
                <div
                  key={item.time}
                  className={classNames("item", {
                    active: item.time === game_type,
                  })}
                  onClick={() => setGameType(item.time)}
                >
                  <div className="period-widget-header">
                    <h6>{i18next.t("time")}</h6>
                    <h4>
                      {item.time}
                      <small>Giây</small>
                    </h4>
                  </div>
                  <p>Lợi nhuận {formatNumber(item.profit, "0,0.[00]")}%</p>
                  <p>
                    Phạm vi {formatMoney(item.min)} - {formatMoney(item.max)}
                  </p>
                </div>
              ))}
            </div>
            <div className="info-table">
              <input
                type="number"
                className="input-number"
                inputMode="decimal"
                placeholder={i18next.t("transaction_amount")}
                value={amount}
                onChange={(e) => setAmount(e?.target?.value)}
              />
            </div>
            <div className="item-row">
              <span className="!text-white">
                {i18next.t("balance_available")}:{" "}
                {formatNumber(usdtBalance?.amount, "0,0.[0000]")}
              </span>
              <span className="!text-white">
                {i18next.t("transaction_fee")}: {formatNumber(0.3)}%
              </span>
            </div>
            <div className="info-table mb-2">
              <div className="item">
                <span>{i18next.t("first_name")}</span>
                <span>
                  <b className="text-uppercase">{data_game?.name}</b>
                </span>
              </div>
              <div className="item">
                <span>{i18next.t("direction")}</span>
                {type === "sell" && (
                  <span className="text-red">{i18next.t("sell")} </span>
                )}
                {type === "buy" && (
                  <span className="text-green">{i18next.t("buy")} </span>
                )}
              </div>
              <div className="item">
                <span>{i18next.t("current_price")}</span>
                <span>{formatNumber(dataGame?.lastPrice, "0,0.[0000]")}</span>
              </div>
              <div className="item">
                <span>{i18next.t("transaction_amount")}</span>
                <span>{formatNumber(amount, "0,0.[0000]")}</span>
              </div>
            </div>
            <button
              type="button"
              className="btn-confirm"
              onClick={() => handleSubmit()}
            >
              {i18next.t("order_comfirmation")}
            </button>
          </div>
        </div>
      </div>
    </Portal>
  );
});

export default ModalBet;

import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { AuthApi } from "states/api";
import { useStores } from "_common/hooks";
import Loading from "_common/component/Loading";
import i18next from "i18next";
import ActiveCode from "./ActiveCode";

const schema = yup
  .object({
    username: yup.string().required(i18next.t("verify_require_username")),
    password: yup.string().required(i18next.t("verify_require_password")),
    confirmed: yup
      .string()
      .required(i18next.t("verify_require_confirm_password"))
      .oneOf([yup.ref("password")], i18next.t("verify_unmatch_password")),
  })
  .required();

export default function RegisterForm({ type }) {
  const { authStore } = useStores();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const ref_no = params?.get("ref_no");

  const { mutate, isLoading } = useMutation(
    (variables) => AuthApi.register(variables),
    {
      onSuccess: (res) => {
        authStore.updateToken(res?.data);
        authStore.updateUser(res?.data);
        setTimeout(() => {
          navigate("/");
        }, 200);
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleRegister = (values) => {
    if (isLoading) return;
    mutate(values);
  };

  useEffect(() => {
    if (!ref_no) return;
    setValue("ref_no", ref_no);
  }, [ref_no]);

  return (
    <form id="auth-sign-up" onSubmit={handleSubmit(handleRegister)}>
      {isLoading && <Loading />}
      <div className="sbui-space-col sbui-space-y-6">
        <div className="sbui-space-col sbui-space-y-3">
          <div>
            <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
              <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                <div className="sbui-formlayout__label">
                  {i18next.t("username_login")}
                </div>
              </div>
              <div className="sbui-formlayout__content-container-horizontal">
                <div className="sbui-input-container">
                  <input
                    type="text"
                    placeholder={i18next.t("verify_require_phone")}
                    className="sbui-input sbui-input--with sbui-input--medium"
                    {...register("username")}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
              <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                <div className="sbui-formlayout__label">
                  {i18next.t("full_name")}
                </div>
              </div>
              <div className="sbui-formlayout__content-container-horizontal">
                <div className="sbui-input-container capcha">
                  <input
                    placeholder={i18next.t("verify_require_name")}
                    type="text"
                    className="sbui-input sbui-input--with sbui-input--medium"
                    {...register("full_name")}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
              <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                <div className="sbui-formlayout__label">
                  {i18next.t("password")}
                </div>
              </div>
              <div className="sbui-formlayout__content-container-horizontal">
                <div className="sbui-input-container">
                  <input
                    autoComplete="current-password"
                    placeholder={i18next.t("password_input")}
                    type="password"
                    className="sbui-input sbui-input--with sbui-input--medium"
                    {...register("password")}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
              <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                <div className="sbui-formlayout__label">
                  {i18next.t("confirmed_password")}
                </div>
              </div>
              <div className="sbui-formlayout__content-container-horizontal">
                <div className="sbui-input-container">
                  <input
                    autoComplete="current-password"
                    placeholder={i18next.t("confirmed_password")}
                    type="password"
                    className="sbui-input sbui-input--with sbui-input--medium"
                    {...register("confirmed")}
                  />
                </div>
              </div>
            </div>
          </div>
          {Number(authStore.general?.allow_ref_no) === 1 && (
            <div>
              <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
                <div className="sbui-space-row sbui-space-x-2 sbui-formlayout__label-container-horizontal">
                  <div className="sbui-formlayout__label">
                    {i18next.t("ref_text")}
                  </div>
                </div>
                <div className="sbui-formlayout__content-container-horizontal">
                  <div className="sbui-input-container">
                    <input
                      disabled={!!ref_no}
                      type="text"
                      placeholder={i18next.t("ref_text")}
                      className="sbui-input sbui-input--with sbui-input--medium"
                      {...register("ref_no")}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="sbui-space-col sbui-space-y-6">
          <span className="sbui-btn-container sbui-btn--w-full">
            <button
              className="sbui-btn sbui-btn-primary btn-register sbui-btn--w-full sbui-btn-container--shadow sbui-btn--large sbui-btn--text-align-center"
              type="submit"
              disabled={!isValid || isLoading}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="sbui "
              >
                <rect x="3" y="11" width="18" height="11" rx="2" ry="2" />
                <path d="M7 11V7a5 5 0 0 1 10 0v4" />
              </svg>
              <span>{i18next.t("register")}</span>
            </button>
          </span>
        </div>
        <div
          className="sbui-space-col sbui-space-y-2"
          style={{ textAlign: "center" }}
        >
          <span
            onClick={() => navigate("/auth/login")}
            className="text-primary px-4 py-2 mx-auto "
          >
            {i18next.t("note_20")}
          </span>
        </div>
      </div>
    </form>
  );
}
